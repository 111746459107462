<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-end px-3">
              <CButton size="sm" color="secondary" @click="fetchData">
                <CIcon name="cil-loop-circular" />
              </CButton>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow><span class="p-3 h5">Revenue</span></CRow>
            <CRow class="justify-content-start">
              <CCol class="col-2">
                <CCallout color="info">
                  <small class="text-muted">GMV</small><br />
                  <strong class="h4">{{
                    revenue.gossValue | currency100
                  }}</strong>
                </CCallout>
              </CCol>

              <CCol class="col-2">
                <CCallout color="danger">
                  <small class="text-muted">Net Revenue</small><br />
                  <strong class="h4">{{
                    revenue.netRevenue | currency100
                  }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout
                  color="dark"
                  v-c-tooltip="'Net revenue compound monthly growth rate'"
                >
                  <small class="text-muted">CMGR</small><br />
                  <strong class="h4">{{ netRevenueRate.toFixed(4) }}</strong>
                </CCallout>
              </CCol>
            </CRow>
            <hr />
            <CRow><span class="p-3 h5">Provider</span></CRow>
            <CRow class="justify-content-start">
              <CCol class="col-2">
                <CCallout color="primary">
                  <small class="text-muted">Pending</small><br />
                  <strong class="h4">{{ provider.pending }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout color="success">
                  <small class="text-muted">Approved </small><br />
                  <strong class="h4">{{ provider.approved }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout color="info">
                  <small class="text-muted">Active </small><br />
                  <strong class="h4">{{ provider.active }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout color="dark">
                  <small class="text-muted">Rejected </small><br />
                  <strong class="h4">{{ provider.rejected }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout color="danger">
                  <small class="text-muted">Suspended </small><br />
                  <strong class="h4">{{ provider.suspended }}</strong>
                </CCallout>
              </CCol>
              <CCol class="col-2">
                <CCallout color="secondary">
                  <small class="text-muted">Banned </small><br />
                  <strong class="h4">{{ provider.banned }}</strong>
                </CCallout>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AdminProxy from '@/proxies/admin.proxy';

export default {
  name: 'Dashboard',
  components: {},
  data() {
    return {
      revenue: {
        gossValue: 0,
        netRevenue: 0,
      },
      provider: {
        pending: 0,
        approved: 0,
        active: 0,
        rejected: 0,
        suspended: 0,
        banned: 0,
      },
      netRevenueRate: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      AdminProxy.dashboardMetrics().then(({ data }) => {
        let { transaction, provider, payout } = data;

        const totalNetRevenue = payout
          .map((x) => x.net_revenue)
          .reduce((a, b) => a + b, 0);

        this.revenue.gossValue = transaction['total_charge'];
        this.revenue.netRevenue = totalNetRevenue;

        this.provider.pending = provider['pending'];
        this.provider.approved = provider['approved'];
        this.provider.active = provider['active'];
        this.provider.rejected = provider['rejected'];
        this.provider.suspended = provider['suspended'];
        this.provider.banned = provider['banned'];

        if (payout.length >= 2) {
          const currentDate = this.moment();
          const previousMonth = currentDate.subtract(1, 'months');

          let first = payout[0];
          let last = payout[payout.length - 1];
          let firstDate = this.moment(first.date_string, 'YYYY-MM');
          let lasttDate = this.moment(last.date_string, 'YYYY-MM');

          //find the end of last month
          for (let i = payout.length - 1; i >= 0; i--) {
            last = payout[i];
            if (previousMonth.format('YYYY-MM') == last.date_string) {
              lasttDate = this.moment(last.date_string, 'YYYY-MM');
              break;
            }
          }

          const diff = lasttDate.diff(firstDate, 'months', true) + 1;
          const x = (1.0 * last.net_revenue) / first.net_revenue;
          const y = 1 / (1.0 * diff);
          this.netRevenueRate = Math.pow(x, y) - 1;
        }
      });
    },
  },
};
</script>
